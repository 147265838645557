.termsPolicyWrapp {
  margin-top: 12px;
  display: flex;
}

.circleWrapp {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: #6e7191;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textWrapp {
  color: white;
  margin-left: 10px;
}

.chmark {
  cursor: pointer;
  outline: none;
}

/* responsive */
@media only screen and (max-width: 777px) {
  .textWrapp {
    font-size: 12px;
  }
}
