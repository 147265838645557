.progressWrapp {
  position: absolute;
  bottom: 20px;
}

.progressBody {
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemBig {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: white;
  margin: 0 4px;
}

.smallItemBox {
  display: flex;
  align-items: center;
}

.itemSmall {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: white;
  margin: 0 2px;
}
