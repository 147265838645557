@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* overflow-x: hidden; */

}

body {
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #14142B !important;
  /* zoom: 90%; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#time-needle {
  -webkit-appearance: none;
  /* appearance: none;
  width: 100%;
  height: 10px;
  background: #d3d3d3; */
}

#time-needle::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 100px;
  width: 15px;
  /* margin-left: -8px; */
  border: 0;
  background: url("./files/slider-needle.png");
  cursor: pointer;
}

.sketch-picker {
  background-color: #262338 !important;
}

.text-options {
  background: repeating-linear-gradient(45deg,
      #606dbc,
      #606dbc 10px,
      #465298 10px,
      #465298 20px);
}

.options-cloud:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 25px;
  top: 85px;
  border: 15px solid;
  border-color: #4E4B66 transparent transparent transparent;
}

.movie-options-cloud:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 30px;
  top: -30px;
  border: 15px solid;
  border-color: transparent transparent #4E4B66 transparent;
}

#attribute-bar::-webkit-scrollbar {
  height: 5px;
}

textarea::-webkit-scrollbar,
.vertical-scroll-box::-webkit-scrollbar,
#body-picker::-webkit-scrollbar,
.dialog-content::-webkit-scrollbar,
#pex-body-picker::-webkit-scrollbar {
  width: 5px;
}

/* Track */
textarea::-webkit-scrollbar-track,
.vertical-scroll-box::-webkit-scrollbar-track,
#attribute-bar::-webkit-scrollbar-track,
#body-picker::-webkit-scrollbar-track,
.dialog-content::-webkit-scrollbar-track,
#pex-body-picker::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
textarea::-webkit-scrollbar-thumb,
.vertical-scroll-box::-webkit-scrollbar-thumb,
#attribute-bar::-webkit-scrollbar-thumb,
#body-picker::-webkit-scrollbar-thumb,
.dialog-content::-webkit-scrollbar-thumb,
#pex-body-picker::-webkit-scrollbar-thumb {
  background: #4E4B66;
  border-radius: 10px;
}

/* Handle on hover */
textarea::-webkit-scrollbar-thumb:hover,
.vertical-scroll-box::-webkit-scrollbar-thumb:hover,
#attribute-bar::-webkit-scrollbar-thumb:hover,
#body-picker::-webkit-scrollbar-thumb:hover,
.dialog-content::-webkit-scrollbar-thumb:hover,
#pex-body-picker::-webkit-scrollbar-thumb:hover {
  background: #8a85b5;
}


#asset-list::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

#asset-list::-webkit-scrollbar-track {
  background: transparent;
}

#asset-list::-webkit-scrollbar-thumb {
  background: #4E4B66;
  border-radius: 10px;
}

#asset-list::-webkit-scrollbar-thumb:hover {
  background: #8a85b5;
}

.canvas-container-full {
  position: absolute !important;
  width: 786px;
  height: 442px;
}

.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 10px;
  height: 25px;
  width: 25px;
  text-align: center;
  color: white;
  transform: scale(1);
  animation: pulse-black 2s infinite;
  position: absolute;
  top: 0;
  right: 0;
}



.blob.green {
  background: rgba(51, 217, 178, 1);
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}

#time-needle-preview {
  -webkit-appearance: none;
  margin-right: 15px;
  width: 200px;
  height: 3px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  background-image: linear-gradient(#FFFFFF, #FFFFFF);
  background-size: 70% 100%;
  background-repeat: no-repeat;
}

/* Input Thumb */
#time-needle-preview::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #FFFFFF;
  cursor: ew-resize;
  transition: background .3s ease-in-out;
}

#time-needle-preview::-moz-range-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #FFFFFF;
  cursor: ew-resize;
  transition: background .3s ease-in-out;
}

#time-needle-preview::-ms-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #FFFFFF;
  cursor: ew-resize;
  transition: background .3s ease-in-out;
}

#time-needle-preview::-webkit-slider-thumb:hover {
  background: #FFFFFF;
}

#time-needle-preview::-moz-range-thumb:hover {
  background: #FFFFFF;
}

#time-needle-preview::-ms-thumb:hover {
  background: #FFFFFF;
}

/* Input Track */
#time-needle-preview::-webkit-slider-runnable-track  {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

#time-needle-preview::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

#time-needle-preview::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.play-disabled {
  cursor: none;
}

.play-disabled svg {
  fill: gray
}


.play-disabled rect {
  fill: gray
}
