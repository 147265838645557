.tmWrapp {
  width: 99%;
  background-color: #262338;
  border-radius: 16px;
  margin-left: 0px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px 0;
}

.wrapperSingle {
  width: 95%;
  display: flex;
  align-items: center;
  overflow: auto;
  justify-content: center;
  margin-right: 14px;
}

.titleTm {
  width: 100%;
}

.title {
  font-weight: 600;
  font-family: 'Poppins';
  font-size: 22px;
  margin-left: 30px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #797878;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #14142b;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #12111a;
}

/* SINGLE TESTIMONIAL */
.singleTmWrapp {
  width: 24%;
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin-left: 14px;
}

.singleTmWrappOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.singleTmText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 24px;
}

.image {
  width: 100%;
  height: 190px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
