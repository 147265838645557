.modalPassForgotWrapp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: rgba(0, 0, 0, 0.88);
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';
}

.modalPassForgotWrappInner {
  padding: 40px 70px;
  width: 586px;
  height: 433px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-color: #14142b;
  z-index: 10;
  color: white;
}

.descrText {
  font-size: 15px;
  margin-top: 13px;
  text-align: center;
}

.resetPassTitle {
  margin-top: 13px;
}

.inpWrapp {
  width: 100%;
}

.modalPassForgotWrappInner input {
  width: 99%;
  height: 58px;
  border-radius: 10px;
  padding-left: 7px;
  background-color: #262338;
  border: 1px solid #262338;
  outline: none;
  color: white;
}

.modalPassForgotWrappInner button {
  margin-top: 29px;
  color: #4700ab;
  font-weight: bold;
  padding: 10px;
  width: 99%;
  background-color: #a996ff;
  border: 1px solid #a996ff;
  border-radius: 10px;
}

.wrappHelperClear {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/*////////  REPSONIVE DESIGN /////////// */
@media only screen and (max-width: 666px) {
  .modalPassForgotWrappInner {
    padding: 30px 25px;
    width: 90%;
    height: 373px;
  }

  .descrText {
    font-size: 13px;
    margin-top: 13px;
    text-align: center;
  }

  .resetPassTitle {
    margin-top: 13px;
    text-align: center;
    font-size: 22px;
  }

  .inpWrapp span {
    font-size: 12px;
  }

  .modalPassForgotWrappInner input {
    width: 99%;
    height: 48px;
  }

  .modalPassForgotWrappInner button {
    margin-top: 19px;
    padding: 7px;
  }
}

@media only screen and (max-width: 444px) {
  .modalPassForgotWrappInner {
    padding: 30px 10px;
    width: 90%;
    height: 373px;
  }

  .descrText {
    font-size: 12px;
  }
}
