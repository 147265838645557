.quttonWrapp {
  width: 587px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 43px;
  margin-left: 30px;
}

.qutton {
  position: absolute;
  right: 45px;
  bottom: 0;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: transparent;
  cursor: pointer;
}

.quttonTxt {
  font-size: 18px;
  margin-right: 10px;
}

.quttonSign {
  margin-top: 3px;
  font-size: 32px;
}
