.dropdownQuestWrapp {
  width: 587px;
  height: 52px;
  border-radius: 12px;
  margin-top: 5px;
  position: relative;
}

.dropdownQuest {
  width: 100%;
  font-weight: 500;
  font-size: 15px;
  height: 52px;
  background-color: #262338;
  outline: none;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  cursor: pointer;
}

.arrowDown {
  margin-right: 15px;
}

/* dropdown */
.dropListWrapp {
  position: absolute;
  top: 46px;
  left: 0;
  overflow: auto;
  width: 100%;
  z-index: 15;
}

.dropList {
  width: 100%;
  padding: 0px;
  margin: 0;
  min-height: 0px;
  max-height: 155px;
  overflow: auto;
  background-color: #262338;
}

.dropList:nth-child(n + 3) {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.dropList:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.dropdownItem {
  border-top: 1px solid white;
  width: 100%;
  list-style-type: none;
  background-color: #262338;
  padding: 12px 8px;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: rgb(52, 47, 47);
}

.dropdownItemTxt {
  margin-left: 10px;
}
