.mainPreviewRightInner {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
}

.detailsHeader {
  width: 100%;
}
.detailsQuestion {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.detailsDescription {
  font-size: 11px;
}

.detailsFooter {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.btnChooseLib {
  height: 25px;
  background-color: transparent;
  border: 0.53px solid #ffffff;
  font-size: 11px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 7px;
  font-weight: 600;
}

.btnRecordVideo {
  margin-left: 5px;
  height: 25px;
  background-color: #a996ff;
  border: 0.53px solid #a996ff;
  font-size: 11px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4700ab;
  border-radius: 7px;
  font-weight: 600;
}
