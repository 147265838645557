.questionBox {
}
.questionParagraph {
  font-weight: 500;
  opacity: 0.5;
}

.txtAreaWrapp {
  width: 587px;
  height: 85px;
  background-color: #262338;
  border-radius: 12px;
  position: relative;
  margin-top: -17px;
  margin-bottom: 25px;
}

.txtArea {
  width: 100%;
  height: 100%;
  background-color: #262338;
  border-radius: 12px;
  outline: none;
  padding: 10px;
  color: white;
  font-size: 15px;
  font-weight: 500;
}

.imgtxtArea {
  position: absolute;
  bottom: 12px;
  right: 16px;
  cursor: pointer;
}
