.succWrapp {
  width: 586px;
  height: 230px;
  background-color: #14142b;
  padding: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MsgLogoWrapp {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.titleSucc {
  margin-top: 15px;
}

.descSuc {
  margin-top: 15px;
  font-size: 15px;
  text-align: center;
}
