.templateImg {
  width: 45%;
  height: 100%;
  border-radius: 20px;
  cursor: pointer;
}

.templateContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.buttonArea {
  padding-top: 15px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
