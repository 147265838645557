.delModWrapp {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  z-index: 999999;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: #14142b;
  padding: 26px;
  border-radius: 12px;
  min-width: 400px;
  position: relative;
}

.containerText {
  width: 91%;
  color: white;
}

.btnWrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 7px;
}

.cancelBtn {
  background-color: #14142b;
  color: #4e4b66;
  border-radius: 8px;
  border: 3px solid #4e4b66;
  font-weight: bold;
  padding: 8px 24px;
  margin-right: 10px;
  border: 3px solid #4e4b66;
}

.deleteBtn {
  background-color: #a996ff;
  border: 1px solid #a996ff;
  color: #4700ab;
  border-radius: 8px;
  font-weight: bold;
  padding: 8px 24px;
}
