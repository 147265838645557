@media only screen and (max-width: 1400px) {
    #frameResp{
      padding:10px 0 15px 0
    }
    .imgresp{
        width:40%;
        height: 65px;
    }
    .paraResp{
      font-size: 0.87rem;
     }

  .paraResp .headOneresp{
      padding-top: 0px;
    }

    .paraResp .headOneresp >p{
      margin: 5px 4px;
      padding: 3px 21px;
    }
    
  .paraResp  .headTwoResp{
    padding: 5px 0;
  }

  .paraResp .headThreeResp{
    padding: 3px 0;
    padding-right:0px; 
  }
  .headThreeRespBtn{
    font-size: 0.79rem;
    margin:3px;
    padding:4px 12px;
    border-radius: 8px;
    border: 3px solid #9988E6;
    color:#9988E6;
    background-Color: #23382f;
  }

  }