.subHeadWrapp {
  width: 100%;
}

.subHeadMain {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.leftSubHead {
  display: flex;
  align-items: center;
}
