.cont {
  flex-direction: column; 
}

.buttoncont {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.buttoncontL {
  width: 100%;
  justify-content: space-between;
}

.buttoncontLogin {
  margin-bottom: 0px;
}

.input {
  width: 100%;
  background-color: #262338;
  border: 1px solid #262338;
  height: 45px;
  border-Radius: 12px;
  color: white;
  padding-Left: 20px;
}

.label {
  font-Family: "Poppins";
  color: white;
}

.buttonClass {
  width: 35%;
  background-Color: #A996FF;
  color: #4700AB;
  padding: 18px 20px;
  margin-Top: 30px;
  border-Radius: 20px;
  font-family: "Poppins";
  font-Style: normal;
  font-Weight: 700;
  font-Size: 17px;
  line-Height: 26px;
  cursor: pointer;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
}

.button:hover {
  transition-duration: 0.1s;
  background-color: #c9befa;
}

.button:after {
  content: "";
  display: block;
  border-radius: 4em;
  opacity: 0;
  transition: all 0.5s;
  box-shadow: 0 0 10px 40px white;
}

.button:active:after {
  box-shadow: 0 0 0 0 white;
  border-radius: 4em;
  opacity: 1;
  transition: 0s;
}

.error {
  color: red;
}

/* /////signup  */
.already,
.loginHere {
  color: white;
}

.label {
  margin-top: 12px;
}

.bottomMargSignup {
  margin-bottom: 0px;
}

.forgotPassWrapp {
  display: flex;
  justify-content: flex-end;
}

.forgotPass {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.modalPassForgotWrapp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: rgba(0, 0, 0, 0.75);
  justify-content: center;
  align-items: center;
}

.succSignWrapp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fadeSuccOverlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 12;
  background-color: rgba(0, 0, 0, 0.75);
}

.wrapInpImg {
  position: relative;
}

.inputImg {
  display: none;
  position: absolute;
  top: 12px;
  left: 10px;
  height: 16px;
  width: 20px;
}

/*//////////////////////////////////
             RESPONSIVE
////////////////////////////////////  */

@media only screen and (max-width: 777px) {
  .label {
    font-size: 12px;
  }

  .input {
    height: 39px;
  }

  .error {
    font-size: 12px;
  }

  .button {
    width: 33%;
    padding: 12px 18px;
    margin-Top: 12px;
    font-Size: 17px;
    line-Height: 21px;
  }

  .already {
    font-size: 14px;
  }
}


@media only screen and (max-width: 556px) {

  .inputImg {
    display: block;
  }

  .input {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 444px) {

  .label {
    margin-top: 5px;
  }

  .input {
    font-size: 12px;
  }

  .button {
    width: 44%;
  }

  .bottomMargSignup {
    margin-bottom: 50px;
  }

  .buttoncontLogin {
    margin-bottom: 140px;
  }
}