.preivewQuestWrapp {
  padding: 12px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preivewArrowsWrapp {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

/* .preivewBoxWrapp{
    width: 447px;
    height: 206px;
    background-image: linear-gradient(to right bottom, #14142b, #1a1d5a, #24228c, #3c21be, #610bef);
} */

.preivewArrow {
  font-size: 37px;
  cursor: pointer;
}
