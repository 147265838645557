.wrappMobileMenu {
  width: 100%;
  padding: 10px;
}

.mobileMenu {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/* ///////////////// */

.mobileSidebarWrapp {
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.25);
  height: 68px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 122;
  display: flex;
  align-items: center;
}

.headerWrapp {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.innerWrapp {
  width: 100vw;
  height: 100vh;
  background-color: #14142b;
}

.innerMenu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

@media only screen and (max-width: 364px) {
  .mobileSidebarWrapp {
    width: 101vw;
  }

  .innerWrapp {
    width: 101vw;
    /* height: 120vh; */
  }
}
