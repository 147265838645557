.bStepTwoWrapp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  padding-right: 40px;
}

.button {
  display: flex;
  align-items: center;
  width: 154px;
  height: 59px;
  border-radius: 12px;
  border: 1px solid white;
}
