.loginParent {
  width: 100vw;
  display: flex;
  overflow: auto;
}

.imgWrap {
  height: 100%;
  width: 42%;
  background-position: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-Content: center;
  align-Items: center;
}

.img {
  max-Width: 100%;
  max-Height: 100vh;
  margin-Bottom: 200px;
}

.text {
  position: absolute;
  color: white;
  font-Family: "Poppins";
  font-Style: normal;
  font-Weight: 700;
  font-Size: 42px;
  line-Height: 50px;
  text-Align: center;
  margin-Top: 200px;
  inline-Size: 350px;
  overflow-Wrap: break-word;
}

.rightpart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 58%;
  height: 100%;
  align-items: center;
  padding-left: 35px;
}

.formWrapp {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.getstartedgrid {
  display: flex;
  align-Items: center;
  margin-Left: 50px;
  margin-Bottom: 20px;
  min-width:55%;

}

.getstarted {
  color: white;
  font-Family: "Poppins";
  font-Weight: 700;
  font-Size: 42px;
}

.inputsgrid {
  display: flex;
  flex-direction: column;
  width: 55%;
  margin-left: 50px;
}


/*//////////////////////////////////
             RESPONSIVE
////////////////////////////////////  */

@media only screen and (max-width: 999px) {
  .text {
    font-size: 30px;
  }

  .inputsgrid {
    width: 85%;
    margin-left: 20px;
  }

  .getstartedgrid {
    margin-left: 20px;
    padding-top: 30px;
  }

  .getstarted {
    font-size: 33px;
  }
}

@media only screen and (max-width: 777px) {
  .img {
    height: 95px;
  }

  .text {
    font-size: 17px;
  }

  .loginParent {
    width: 100vw;
    display: flex;
    flex-direction: column;
  }

  .imgWrap {
    width: 100%;
    height: 33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .rightpart {
    width: 100%;
    height: 67%;
  }

  .img {
    height: 110px;
    margin-Bottom: 0px;
  }

  .text {
    font-size: 22px;
    bottom: 22px;
  }

  .inputsgrid {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 444px) {
  .img {
    height: 95px;
  }

  .text {
    font-size: 17px;
    bottom: 5px;
  }

  .getstartedgrid {
    margin-left: 0px;
  }

  .getstarted {
    font-size: 25px;
  }

}