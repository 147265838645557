.preivewBoxWrapp {
  width: 447px;
  height: 206px;
  font-family: 'Poppins';
  background-image: linear-gradient(to right bottom, #14142b, #1a1d5a, #24228c, #3c21be, #610bef);
}

.headerWrapp {
  width: 100%;
  height: 16%;
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
}

.headerPara {
  padding: 0;
  margin: 0;
  font-size: 12px;
}

/* MAIN PREV */

.mainPreviewQuests {
  width: 100%;
  height: 74%;
  display: flex;
  overflow: auto;
}

/* main prev left */
.mainPreviewLeft {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.mainPreviewLeftInner {
  padding: 5px;
  width: 82%;
  height: 100%;
  overflow: auto;
}

.itemQuestion {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 11px;
}

.itemQuestion input {
  cursor: pointer;
}
/* main prev right */
.mainPreviewRight {
  width: 75%;
  height: 100%;
}

.mainPreviewRightInner {
  width: 90%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
}

.detailsHeader {
  width: 100%;
}
.detailsQuestion {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.detailsDescription {
  font-size: 11px;
}

.detailsFooter {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.btnChooseLib {
  height: 25px;
  background-color: transparent;
  border: 0.53px solid #ffffff;
  font-size: 11px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 7px;
  font-weight: 600;
}

.btnRecordVideo {
  margin-left: 5px;
  height: 25px;
  background-color: #a996ff;
  border: 0.53px solid #a996ff;
  font-size: 11px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4700ab;
  border-radius: 7px;
  font-weight: 600;
}
