.bStepThreeWrapp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bStepThreeInner {
  width: 586px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 154px;
  height: 59px;
  border-radius: 12px;
  background-color: transparent;
  border: 1px solid white;
  opacity: 0.5;
}

.buttonSuccessSign {
  margin-right: 4px;
}

.sentTxt {
  margin-left: 4px;
  color: white;
}

.sendAnotherTxt {
  color: white;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
}
