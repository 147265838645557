@media only screen and (max-width: 1400px) {
  .respPlayCircle {
    width: 57px;
  }

  .timeResp {
    font-size: 12px;
  }
  #sceneListResp {
    padding: 0;
    margin: 0;
  }

  .imgResp {
    height: 90px;
  }
  .timelineWrapperResp {
    height: 105px;
    overflow: hidden;
  }

  #dividLineWrappResp {
    height: 95%;
  }
  .musicRecInnerResp {
    width: 95%;
  }

  .activeImgWrappResp .activeImgResp {
    border: 3px solid #a996ff;
  }

  #recordsResp .iconLinesWrapp {
    font-size: 20px;
  }
}
