.mainQuestionWrapp {
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 500px;
  overflow: auto;
}

.includeStar {
  align-self: flex-start;
  margin-left: 25px;
  margin-top: 20px;
}
