* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.pageWrapp {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  font-family: 'Poppins';
  z-index: 99;
  background-color: #14142b;
}

.lowerPartInner {
  width: 100%;
  height: 100%;
  padding: 20px;
}

.lowerPartTitleWrapp {
  width: 100%;
  padding: 10px 0;
  font-family: 'Poppins';
}

.upperPart {
  width: 100vw;
  height: 40vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoWrapp {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoWrapp h3 {
  color: white;
  font-weight: 700;
  font-size: 26px;
}

.firstTitle {
  margin-top: 18px;
}

.logoImg {
  width: 60px;
}

.lowerPart {
  width: 100vw;
  height: 60vh;
}

.signTitle {
  font-size: 28px;
  color: white;
  font-weight: bold;
  font-family: 'Poppins';
}

.formWrapp {
  width: 100%;
}

.inputBoxWrapp {
  margin-top: 13px;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
}

.label {
  color: white;
  padding-left: 4px;
  font-size: 15px;
}

.inputWrapp {
  width: 100%;
  position: relative;
}

.input {
  width: 100%;
  background-color: #262338;
  border-radius: 10px;
  height: 53px;
  font-size: 15px;
  outline: none;
  border: 1px solid #262338;
  color: white;
  padding-left: 47px;
}

.logoPlaceholderImg {
  position: absolute;
  top: 18px;
  left: 15px;
}

.input::placeholder {
  color: white;
  font-style: italic;
}

.btnWrapp {
  margin-top: 9px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.signupTxt {
  color: white;
  font-size: 12px;
  margin-top: 15px;
}

.errorMsg {
  color: red;
  padding-left: 5px;
  font-size: 12px;
  font-weight: bold;
}
