.modalPassForgotWrapp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.88);
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';
  z-index: 40;
}

.modalPassForgotWrappInner {
  padding: 40px 70px;
  width: 586px;
  height: 317px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-color: #14142b;
  color: white;
  z-index: 50;
  font-family: 'Poppins';
}

.descrText {
  font-size: 15px;
  margin-top: 7px;
  text-align: center;
}

.resetPassTitle {
  margin-top: 13px;
}

.inpWrapp {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -15px;
}

.modalPassForgotWrappInner button {
  margin-top: 10px;
  color: #4700ab;
  font-weight: bold;
  padding: 10px;
  width: 37%;
  background-color: #a996ff;
  border: 1px solid #a996ff;
  border-radius: 10px;
}

.succSignWrapp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fadeSuccOverlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 12;
  background-color: rgba(0, 0, 0, 0.75);
}

/*////////////////////////////////////
  /////////// RESPOSNIVE DESIGN 
  ///////////////////////////////////*/

@media only screen and (max-width: 666px) {
  .modalPassForgotWrappInner {
    padding: 30px 70px;
    width: 90%;
    height: 317px;
  }

  .descrText {
    font-size: 12px;
    margin-top: 12px;
    text-align: center;
  }

  .resetPassTitle {
    margin-top: 13px;
  }

  .modalPassForgotWrappInner button {
    margin-top: 10px;
    width: 47%;
  }
}

@media only screen and (max-width: 444) {
  .modalPassForgotWrappInner button {
    width: 67%;
  }
}
